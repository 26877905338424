<script setup>
import ListTableConfiguracionEmpleados from './table/index.vue'
import ModalConfiguracion from './components/modalConfiguracion.vue'
import { onMounted, ref } from 'vue'

const modalConfiguracion = ref()

// Funcion para abrir el modal
const openModalConfig = async () => {
  modalConfiguracion.value.openModal()
}

// Hook onMounted
onMounted(async () => {
  console.log('onMounted, configuracion empleados...')
})

</script>

<template>
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <Button label="Regresar" icon="pi pi-arrow-left" class="p-button-sm" @click="$router.push({ name: 'pharmasan.administrativa.solicitud-permisos.configuracion'})"/>
    </template>
    <template #end>
      <Button
        label="Asignar configuración masiva"
        icon="pi pi-cog"
        class="p-button-xs"
        severity="success"
        @click="openModalConfig"
      />
    </template>
  </Toolbar>

  <ListTableConfiguracionEmpleados />
  <ModalConfiguracion ref="modalConfiguracion"/>
</template>

<style scoped>

</style>
