<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useConfiguracionEmpleadosStore } from '../../../../stores/configuracionEmpleados'
import FiltersEmpleados from '../components/filters.vue'
import { useRoute, useRouter } from 'vue-router'
import Icon from '@/components/Icon/index.vue'
import ModalEditarConfig from '../components/modalEditarConfig.vue'
import ConfigAprobacionesService from '../../../../services/config-aprobaciones.service'
import Swal from 'sweetalert2'
import { error } from '../../../../../../../../libs/mensajes'

const _ConfigAprobacionesService = ref(new ConfigAprobacionesService())
const configuracionEmpleadosStore = useConfiguracionEmpleadosStore()
const listEmpleados = ref([])
const listAprobadoresComunes = ref([])
const limit = ref(20)
const offset = ref(0)
const orderField = ref('id')
const sortOrder = ref('-1')
const route = useRoute()
const router = useRouter()
const selectedEmpleado = ref()
const expandedRows = ref([])
const modalEditarConfig = ref()

// Computada para obtener el store de filtros
const getterFiltersConfiguracionEmpleados = computed(() => {
  return configuracionEmpleadosStore.getFiltersEmpleados
})

// Función que permite la actualización de parámetros de consulta de manera dinámica antes de realizar la navegación a una nueva URL.
const _routerPush = (obj = {}) => {
  const queryParams = route.query
  router.push({ query: { ...queryParams, ...obj } })
}

// Ordenar por campo
const orderQuery = ({ sortField, sortOrder }) => {
  _routerPush({ order: `${sortField},${sortOrder}` })
}

// Paginación
const onPage = (row) => {
  const queryParams = { ...route.query }
  limit.value = row.rows
  offset.value = row.first

  // _routerPush({ ...queryParams, limit: row.rows, offset: row.first })
  router.push({ query: { ...queryParams, limit: row.rows, offset: row.first } })
}

// Asignacion de los query params
const setQueryParams = () => {
  const queryParams = { ...route.query }
  limit.value = queryParams.limit ? parseInt(queryParams.limit) : 20
  offset.value = queryParams.offset ? parseInt(queryParams.offset) : 0
  queryParams.limit = queryParams.limit ? queryParams.limit : 20
  queryParams.offset = queryParams.offset ? queryParams.offset : 0
  queryParams.order = queryParams.order ? queryParams.order : 'id,-1'

  // Split a la cadena para obtener el campo y el orden
  const splitOrder = queryParams.order.split(',')
  orderField.value = splitOrder[0]
  sortOrder.value = parseInt(splitOrder[1])

  return queryParams
}

// Funcion para obtener el listado de empleados del store
const getEmpleados = async () => {
  await configuracionEmpleadosStore.setListadoEmpleados(setQueryParams())
  listEmpleados.value = configuracionEmpleadosStore.getListadoEmpleados
}

// Funcion para obtener los aprobadores comunes del store
const getAprobadoresComunes = async () => {
  await configuracionEmpleadosStore.setListAprobadoresComunes()
  listAprobadoresComunes.value = configuracionEmpleadosStore.getListAprobadoresComunes
}

// Funcion para retornar el router query de la URL
const getRouteQuery = async () => {
  // Si el objeto no viene vacio
  if (Object.keys(route.query).length !== 0) {
    delete route.query.limit
    delete route.query.offset
    delete route.query.order

    // Parsear los valores de los filtros a enteros
    Object.keys(route.query).forEach((key) => {
      if (['area', 'motivo', 'cargo'].includes(key)) {
        // Si el valor es vacío o string, no se parsea
        if (route.query[key] !== '' && !isNaN(route.query[key])) {
          route.query[key] = parseInt(route.query[key])
        }
      }
    })

    configuracionEmpleadosStore.setFiltersEmpleados(route.query)
  } else {
    configuracionEmpleadosStore.setFiltersEmpleados({})
  }
}

// Expandir y contraer filas
const onRowExpand = (event) => {
  console.log('onRowExpand', event)
}
const onRowCollapse = (event) => {
  console.log('onRowCollapse', event)
}

// Editar aprobación
const editarAprobacion = (data) => {
  modalEditarConfig.value.openModal(data)
}

// Eliminar aprobación
const eliminarAprobacion = (data) => {
  const { id } = data

  if (id) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      html: `Desea eliminar la configuracion de aprobaciones del empleado ${data.full_name}, esta acción no se puede revertir.`,
      showDenyButton: true,
      denyButtonText: 'No, Cancelar',
      confirmButtonText: 'Si, Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        _ConfigAprobacionesService.value.eliminarConfigAprobacionPorEmpleado(id).then(({ status }) => {
          if (status !== 200) return

          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'Se ha eliminado la configuración de aprobaciones correctamente'
          }).then(() => {
            // Recargar la tabla de empleados
            configuracionEmpleadosStore.setRefrescarListadoEmpleados(true)
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    console.error('Error al eliminar la solicitud, por favor intente nuevamente')
  }
}

// Watch si cambian los filtros
watch(getterFiltersConfiguracionEmpleados, async (newFilter) => {
  _routerPush({ ...newFilter })
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async (value) => {
  if (Object.keys(value).length === 0) {
    configuracionEmpleadosStore.setFiltersEmpleados({})
  }
  await getEmpleados()
})

// Watch si cambia selectedEmpleado y agregarlo al store
watch(selectedEmpleado, async (value) => {
  configuracionEmpleadosStore.setSelectedEmpleados(value)
})

// Watch para refrescar la tabla
watch(() => configuracionEmpleadosStore.getRefrescarListadoEmpleados, async (value) => {
  if (value) {
    await getEmpleados()
    configuracionEmpleadosStore.setRefrescarListadoEmpleados(false)
    configuracionEmpleadosStore.setSelectedEmpleados([])
    selectedEmpleado.value = []
  }
})

// Hooks onMounted
onMounted(async () => {
  await getRouteQuery()
  await getEmpleados()
  await getAprobadoresComunes()
  console.log('onMounted, configuracion tabla empleados...')
  configuracionEmpleadosStore.setSelectedEmpleados([])
})

</script>

<template>
  <div class="box intro-x">
    <div class="mt-4">
      <!-- Titulo Card -->
      <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto capitalize text-base font-medium">Listado de empleados</h3>
      </div>

      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4 gap-5">
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <FiltersEmpleados />
        </div>

        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <DataTable
            ref="dt"
            :value="listEmpleados.data"
            v-model:selection="selectedEmpleado"
            v-model:expandedRows="expandedRows"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
            tableStyle="min-width: 50rem"
            class="p-datatable-sm text-xs"
            stripedRows
            @sort="orderQuery"
            dataKey="id"
            :sortField="orderField"
            :sortOrder="sortOrder"
          >
            <template #empty> No hay datos para mostrar. </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column selectionMode="multiple" style="width: 3rem"></Column>
            <Column expander style="width: 5rem" />
            <Column field="id" header="ID" sortable></Column>
            <Column field="cedula" header="Cedula"></Column>
            <Column field="full_name" header="Nombre" sortable></Column>
            <Column field="email" header="Correo" sortable></Column>
            <Column field="areaNombre" header="Área" sortable></Column>
            <Column field="cargoNombre" header="Cargo"></Column>
            <Column field="totalAprobaciones" header="# Aprobaciones" sortable></Column>
            <Column header="Acciones" :exportable="false">
              <template #body="{data}">
                <div class="flex flex-wrap justify-center">
                  <Button
                      icon="pi pi-pencil"
                      :class="`p-button-rounded p-button-warning mr-2`"
                      v-tippy="{ content: `Editar` }"
                      @click="editarAprobacion(data)"
                      :pt="{
                    root: { style: 'width: 30px; height: 30px'},
                  }"
                  />
                  <Button
                      icon="pi pi-trash"
                      :class="`p-button-rounded p-button-danger`"
                      v-tippy="{ content: `Eliminar` }"
                      @click="eliminarAprobacion(data)"
                      :pt="{
                    root: { style: 'width: 30px; height: 30px'},
                  }"
                  />
                </div>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div v-if="slotProps.data.aprobadores.length === 0" class="w-full flex flex-col items-center justify-center">
                <Icon icon="healthicons:not-ok" class="text-5xl text-gray-500"/>
                <span class="text-red-800 opacity-70">El empleado no tiene asignados aprobadores</span>
              </div>
              <div class="" v-else>
                <h5 class="text-sm font-semibold text-gray-600 mb-2">Información de los aprobadores</h5>
                <DataTable
                  :value="slotProps.data.aprobadores"
                  showGridlines
                  responsiveLayout="scroll"
                  class="p-datatable-sm text-xs"
                >
                  <Column field="full_name" header="Nombre" />
                  <Column field="areaNombre" header="Área" />
                  <Column field="cargoNombre" header="Cargo" />
                </DataTable>
              </div>
            </template>
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(listEmpleados.total)"
            :rowsPerPageOptions="[5,10,20,30,100, listEmpleados.total]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
            :pt="{
              rowPerPageDropdown: {
                input: {
                  style: 'padding: 5px'
                },
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>

  <ModalEditarConfig ref="modalEditarConfig" />
</template>

<style scoped>

</style>
