import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS

export default class ConfigAprobacionesService {
  crearConfigAprobacion (payload) {
    return http.post(`${baseUrl}/config-aprobaciones`, payload, {
      headers: {
        loading: true
      }
    })
  }

  findConfigAprobacionByEmpleado (id) {
    return http.get(`${baseUrl}/config-aprobaciones/find-by-employee/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  editarConfigAprobacionPorEmpleado (id, payload) {
    return http.put(`${baseUrl}/config-aprobaciones/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  eliminarConfigAprobacionPorEmpleado (id) {
    return http.delete(`${baseUrl}/config-aprobaciones/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
