<script setup>
import { onMounted, ref, defineExpose, computed, watch } from 'vue'
import { useConfiguracionEmpleadosStore } from '../../../../stores/configuracionEmpleados'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { error, messageConfirmSave } from '../../../../../../../../libs/mensajes'
import ConfigAprobacionesService from '../../../../services/config-aprobaciones.service'
// import { useRoute } from 'vue-router'

// const route = useRoute()
const _ConfigAprobacionesService = ref(new ConfigAprobacionesService())
const configuracionEmpleadosStore = useConfiguracionEmpleadosStore()
const displayModal = ref(false)
const dropdownOtrosAprobadores = ref(false)
const listOtrosAprobadores = ref([])
const isAprobadores = ref(false)

// Computada para obtener los empleados seleccionados
const getterSelectedEmpleado = computed(() => {
  const infoSelectedEmpleados = configuracionEmpleadosStore.getSelectedEmpleados

  // Armar el array de empleados seleccionados solo con el ID y el nombre
  return infoSelectedEmpleados.map((empleado) => {
    return {
      id: empleado.id,
      full_name: empleado.full_name,
      cargoNombre: empleado.cargoNombre,
      areaNombre: empleado.areaNombre
    }
  })
})

// Obtener los aprobadores comunes del store
const listAprobadoresComunes = computed(() => {
  // Agregar una opcion de otros
  const listAprobadores = configuracionEmpleadosStore.getListAprobadoresComunes
  listAprobadores.push({ id: '0', full_name: 'Otros' })

  return listAprobadores
})

// Validar de que existan empleados seleccionados
const validateSelectedEmpleados = () => {
  const selectedEmpleados = getterSelectedEmpleado.value

  return selectedEmpleados.length !== 0
}

// Obtener la lista de empleados aprobadores
const getListEmpleadosAprobadores = async () => {
  isAprobadores.value = true
  listOtrosAprobadores.value = []

  await configuracionEmpleadosStore.setListadoEmpleados({ aprobadores: isAprobadores.value })
  // Eliminar los empleados aprobadores comunes de la lista de otros aprobadores
  listOtrosAprobadores.value = configuracionEmpleadosStore.getListadoEmpleados.filter((empleado) => {
    return !listAprobadoresComunes.value.map((aprobador) => aprobador.id).includes(empleado.id)
  })
}

// Funcion para abrir el modal
const openModal = async () => {
  // Si no hay empleados seleccionados, no se abre el modal
  if (!validateSelectedEmpleados()) {
    Swal.fire({
      title: 'Advertencia',
      text: 'Debe seleccionar al menos un empleado',
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    })
    return
  }
  displayModal.value = true

  await getListEmpleadosAprobadores()
}

/* Cerrar el modal */
const closeModal = () => {
  displayModal.value = false
  model.aprobadores = []
  model.otrosAprobadores = []
  listOtrosAprobadores.value = []
}

// Validacion form
const validationSchema = yup.object({
  aprobadores: yup.array().min(1).of(yup.string().required()).required(),
  // Si selecciona la opcion de otros, se valida que haya al menos un aprobador seleccionado
  otrosAprobadores: yup.array().when('aprobadores', {
    is: (aprobadores) => {
      if (![null, undefined].includes(aprobadores)) {
        return aprobadores.includes('0')
      }
    },
    then: yup.array().min(1).of(yup.string().required()).required()
  })
})

const { errors, handleSubmit, values: model } = useForm({ validationSchema })

useField('aprobadores', null, { initialValue: '' })
useField('otrosAprobadores', null, {
  initialValue: []
})

// Borrar otros aprobadores
const borrarOtrosAprobadores = (aprobador) => {
  const idx = model.otrosAprobadores.indexOf(aprobador)
  model.otrosAprobadores.splice(idx, 1)
}

// Envió de la información
const onSubmit = handleSubmit(async (values) => {
  // console.log('values', values)

  // Si selecciona la opcion de otros, se elimina el valor de la opcion de otros
  if (values.aprobadores.includes('0')) {
    values.aprobadores.splice(values.aprobadores.indexOf('0'), 1)
  }

  // Crear unico array de aprobadores
  const aprobadores = values.aprobadores.concat(values.otrosAprobadores)
  const resultado = []
  // Recorre aprobadores y empleados seleccionados y genera las combinaciones
  for (const aprobadorId of aprobadores) {
    for (const empleado of getterSelectedEmpleado.value) {
      resultado.push({
        aprobadorId: parseInt(aprobadorId), // Convierte el ID a número si es necesario
        empleadoId: parseInt(empleado.id) // Convierte el ID a número si es necesario
      })
    }
  }

  messageConfirmSave('', 'Información', 'warning', '¿Esta seguro de crear la asignación de aprobaciones seleccionada?').then(({ isConfirmed }) => {
    if (isConfirmed) {
      _ConfigAprobacionesService.value.crearConfigAprobacion(resultado).then((res) => {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: 'Se ha creado la asignación de aprobaciones correctamente'
        }).then(() => {
          closeModal()
          // Recargar la tabla de empleados
          configuracionEmpleadosStore.setRefrescarListadoEmpleados(true)
        })
      }).catch((err) => {
        console.error(err.message)
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    }
  })
})

// Si selecciona la opcion de otros, se muestra el input para ingresar el nombre del aprobador
watch(() => model.aprobadores, (newValue) => {
  dropdownOtrosAprobadores.value = !!newValue.includes('0')
})

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  console.log('onMounted, modal configuracion...')
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="false"
    :closable="false"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '60vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Asignar Configuración` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
      <!-- Aprobadores -->
      <div class="sm:col-span-6">
        <label for="" class="block text-sm font-medium text-gray-700 mb-2">Aprobadores:</label>
        <div class="flex flex-wrap justify-around gap-3 flex-col sm:flex-row">
          <div v-for="aprobadorComun in listAprobadoresComunes" :key="aprobadorComun.id" class="flex items-center">
            <Checkbox
              v-model="model.aprobadores"
              :inputId="aprobadorComun.id"
              name="aprobadoresComunes"
              :value="aprobadorComun.id"
              :class="{ 'p-invalid': errors.aprobadores }"
            />
            <div class="flex flex-col">
              <label :for="aprobadorComun.id" class="ml-2 block">{{ aprobadorComun.full_name }}</label>
              <label :for="aprobadorComun.id" class="text-gray-500 text-sm ml-2 block">{{ aprobadorComun.cargoName }}</label>
            </div>
          </div>
        </div>
        <MessageError :text="errors.aprobadores"/>
      </div>

      <!-- Otros aprobadores -->
      <div class="sm:col-span-6" v-if="dropdownOtrosAprobadores">
        <label for="dropdownOtrosAprobadores" class="block text-sm font-medium text-gray-700 mb-2">Otros aprobadores:</label>
        <div class="flex flex-col flex-wrap">
          <MultiSelect
            v-model="model.otrosAprobadores"
            :options="listOtrosAprobadores"
            optionValue="id"
            optionLabel="full_name"
            filter
            class="w-full border-gray-300 rounded-md"
            :class="{ 'p-invalid': errors.otrosAprobadores }"
            placeholder="Aprobador(es)"
            :maxSelectedLabels="3"
            :pt="{
              root: { style: 'height: 37px;' },
              label: { style: 'font-size: 0.8rem; padding: 0.5rem 0.75rem' }
            }"
          >
          </MultiSelect>
          <!-- Mostrar los otros aprobadores seleccionados del multiselect con full_name -->
          <div class="w-full flex flex-wrap gap-2 mt-2 max-h-28 overflow-y-auto">
            <div
                v-for="(oAprob, i) in model.otrosAprobadores"
                :key="i"
                class="max-h-20 overflow-y-auto"
            >
              <div class="bg-blue-200 p-1 flex items-center w-full rounded-md text-blue-800 text-xs">
                <button @click="borrarOtrosAprobadores(oAprob)" class="text-blue-800 mr-2">
                  <i style="font-size: 0.6rem" class="pi pi-times"></i>
                </button>
                <p>{{listOtrosAprobadores.find(a => a.id === oAprob).full_name}}</p>
                <p class="text-sm text-gray-600"> ({{listOtrosAprobadores.find(a => a.id === oAprob).areaNombre}})</p>
              </div>
            </div>
          </div>
        </div>
        <MessageError :text="errors.otrosAprobadores"/>
      </div>

      <!-- Listado de empleados seleccionados -->
      <div class="sm:col-span-6">
        <label for="" class="block text-sm font-medium text-gray-700 mb-2">Empleados seleccionados:</label>
        <DataTable
          ref="dt"
          :value="getterSelectedEmpleado"
          tableStyle="min-width: 50rem"
          class="p-datatable-sm text-xs"
          showGridlines
          dataKey="id"
        >
          <template #empty> No hay datos para mostrar. </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column field="id" header="Id" sortable></Column>
          <Column field="full_name" header="Nombre" sortable></Column>
          <Column field="areaNombre" header="Área" sortable></Column>
          <Column field="cargoNombre" header="Cargo" sortable></Column>
        </DataTable>
      </div>

      <div class="sm:col-span-6">
        <p>
          <span class="font-bold text-xs text-yellow-600">Nota: </span>
          <span class="text-xs text-yellow-600"> Si los empleados seleccionados ya tienen asignados aprobadores, se sobreescribiran con los nuevos que se configuren.</span>
        </p>
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModal" class="p-button-xs p-button-danger"/>
      <Button label="Guardar" icon="pi pi-check" @click="onSubmit" class="p-button-xs p-button-success" autofocus />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
