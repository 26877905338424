<script setup>
import { onMounted, ref, defineExpose, reactive } from 'vue'
import { useConfiguracionEmpleadosStore } from '../../../../stores/configuracionEmpleados'
import Swal from 'sweetalert2'
import ConfigAprobacionesService from '../../../../services/config-aprobaciones.service'
import { error, messageConfirmSave } from '../../../../../../../../libs/mensajes'

const _ConfigAprobacionesService = ref(new ConfigAprobacionesService())
const configuracionEmpleadosStore = useConfiguracionEmpleadosStore()
const displayModal = ref(false)
const listOtrosAprobadores = ref([])
const isAprobadores = ref(false)
const listAprobadoresByEmpleado = ref([])
const newSelectAprobadores = ref([])
const isNewAprobadores = ref(false)
const infoEmpleado = reactive({
  id: null,
  nombre: null
})

// Obtener la lista de empleados aprobadores
const getListAprobadores = async () => {
  isAprobadores.value = true
  listOtrosAprobadores.value = []

  await configuracionEmpleadosStore.setListadoEmpleados({ aprobadores: isAprobadores.value })
  listOtrosAprobadores.value = configuracionEmpleadosStore.getListadoEmpleados
}

// Obtener la lista de aprobadores por el ID del empleado del store
const getListConfigAprobaciones = async (id) => {
  await configuracionEmpleadosStore.setListAprobadoresByEmpleado(id)
  listAprobadoresByEmpleado.value = configuracionEmpleadosStore.getListAprobadoresByEmpleado
  // Agregarle el isNew a cada uno de los aprobadores
  listAprobadoresByEmpleado.value = listAprobadoresByEmpleado.value.map(x => {
    return {
      ...x,
      isNew: false
    }
  })
}

// Funcion para agregar un aprobador a la lista de aprobadores del empleado
const onSelectAprobador = async (e) => {
  // listAprobadoresByEmpleado.value = []

  const listAprobadoresNuevos = []
  for (const aprobador of e.value) {
    // Consultar la información del aprobador de la lista de otros aprobadores
    const aprobadorNuevo = listOtrosAprobadores.value.find(x => x.id === aprobador)
    listAprobadoresNuevos.push({
      id: aprobadorNuevo.id,
      aprobadorId: aprobadorNuevo.id,
      aprobadorNombre: aprobadorNuevo.full_name,
      areaNombre: aprobadorNuevo.areaNombre,
      isNew: true
    })
  }

  // Validar de que si ya existe el aprobador en la lista de aprobadores del empleado no lo pushee
  for (const aprobador of listAprobadoresNuevos) {
    const existeAprobador = listAprobadoresByEmpleado.value.find(x => x.id === aprobador.id)
    if (!existeAprobador) {
      listAprobadoresByEmpleado.value.push(aprobador)
    }
  }

  /* console.log('listAprobadoresByEmpleado', listAprobadoresByEmpleado.value)

  listAprobadoresByEmpleado.value.push(...listAprobadoresNuevos) */
}

// Remover aprobadores
const onRemoveAprobador = (aprobador) => {
  // Remueve el aprobador de listAprobadoresByEmpleado
  listAprobadoresByEmpleado.value = listAprobadoresByEmpleado.value.filter(
      (x) => x.id !== aprobador.id
  )
  // Remueve el aprobador de newSelectAprobadores
  newSelectAprobadores.value = newSelectAprobadores.value.filter(
      (x) => x !== aprobador.id
  )
}

// Funcion para abrir el modal
const openModal = async (data) => {
  console.log('openModal, editar configuracion..')

  infoEmpleado.id = data.id
  infoEmpleado.nombre = data.full_name

  displayModal.value = true
  newSelectAprobadores.value = []
  isNewAprobadores.value = false
  await getListAprobadores()
  await getListConfigAprobaciones(data.id)
}

/* Cerrar el modal */
const closeModal = () => {
  displayModal.value = false
  newSelectAprobadores.value = []
}

//
const onSubmit = () => {
  if (listAprobadoresByEmpleado.value.length > 0) {
    // Agregar el ID del empleado a la lista de aprobadores y solo mostrar id, empleadoId, aprobadorId
    const sendListAprobadores = listAprobadoresByEmpleado.value.map(x => {
      return {
        empleadoId: infoEmpleado.id,
        aprobadorId: x.aprobadorId
      }
    })

    messageConfirmSave('', 'Información', 'warning', '¿Esta seguro de generar la nueva asignación de aprobaciones para el empleado?').then(({ isConfirmed }) => {
      if (isConfirmed) {
        _ConfigAprobacionesService.value.editarConfigAprobacionPorEmpleado(infoEmpleado.id, sendListAprobadores).then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'Se ha creado la nueva asignación de aprobaciones correctamente'
          }).then(() => {
            closeModal()
            // Recargar la tabla de empleados
            configuracionEmpleadosStore.setRefrescarListadoEmpleados(true)
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  }
}

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  console.log('onMounted, modal configuracion editar...')
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="false"
    :closable="false"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '60vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Editar Configuración del empleado ${infoEmpleado.nombre}` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <div class="flex justify-end">
          <Button
              size="small"
              label="Agregar aprobador..."
              outlined
              @click="isNewAprobadores = !isNewAprobadores"
          />
        </div>
        <div class="flex flex-col flex-wrap mt-2" v-if="isNewAprobadores">
          <label for="dropdownOtrosAprobadores" class="block text-sm font-medium text-gray-700 mb-2">Seleccione aprobadores a agregar:</label>
          <MultiSelect
            v-model="newSelectAprobadores"
            :options="listOtrosAprobadores"
            optionValue="id"
            optionLabel="full_name"
            @change="onSelectAprobador"
            filter
            class="w-full border-gray-300 rounded-md"
            placeholder="Aprobador(es)"
            :maxSelectedLabels="3"
            :pt="{
              root: { style: 'height: 37px;' },
              label: { style: 'font-size: 0.8rem; padding: 0.5rem 0.75rem' }
            }"
          >
          </MultiSelect>
        </div>
      </div>
      <div class="sm:col-span-6">
        <label for="" class="block text-sm font-medium text-gray-700 mb-2">Aprobadores del empleado:</label>
        <DataTable
          ref="dt"
          :value="listAprobadoresByEmpleado"
          tableStyle="min-width: 50rem"
          class="p-datatable-sm text-xs"
          showGridlines
          dataKey="id"
          :rowStyle="row => row.isNew ? 'background-color: #E2EFDA' : ''"
        >
          <template #empty> No hay datos para mostrar. </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column field="id" header="Id" sortable></Column>
          <Column header="Nombre" sortable>
            <template #body="{data}">
              <div class="flex flex-wrap justify-between">
                <span>{{ data.aprobadorNombre }}</span>
                <span v-if="data.isNew" class="text-green-900 text-xxs font-bold underline">Nuevo</span>
              </div>
            </template>
          </Column>
          <Column field="areaNombre" header="Área" sortable></Column>
          <Column header="Acciones" class="text-center">
            <template #body="{data}">
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-sm"
                @click="onRemoveAprobador(data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" severity="secondary" icon="pi pi-times" @click="closeModal" class="p-button-xs"/>
      <Button label="Guardar" severity="success" icon="pi pi-check" @click="onSubmit" class="p-button-xs" autofocus :disabled="listAprobadoresByEmpleado.length <= 0"/>
    </template>
  </Dialog>
</template>

<style scoped>

</style>
