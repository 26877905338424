<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useConfiguracionEmpleadosStore } from '../../../../stores/configuracionEmpleados'
import EmpleadoService from '../../../../services/empleado.service'

const _EmpleadoService = ref(new EmpleadoService())
const configuracionEmpleadosStore = useConfiguracionEmpleadosStore()
const listCargos = ref([])
const listAreas = ref([])
const filters = reactive({
  cedula: '',
  area: '',
  cargo: ''
})

// Obtener las áreas
const fetchAreas = async () => {
  await _EmpleadoService.value.listarAreas().then((res) => {
    listAreas.value = res.data
    // Agregar el item de todos
    listAreas.value.unshift({ id: '', name: 'Todos' })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Obtener los cargos
const fetchCargos = async () => {
  await _EmpleadoService.value.listarCargos().then((res) => {
    listCargos.value = res.data
    // Agregar el item de todos y sin cargo
    listCargos.value.unshift({ id: '', name: 'Todos' })
    listCargos.value.unshift({ id: '0', name: 'Sin cargo' })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Realizar la búsqueda y setear los filtros en el store
const updateFilter = () => {
  const filtersData = {
    cedula: filters.cedula ?? '',
    area: filters.area ?? '',
    cargo: filters.cargo ?? ''
  }

  configuracionEmpleadosStore.setFiltersEmpleados(filtersData)
}

// Limpiar los filtros
const resetClear = () => {
  for (const [key] of Object.entries(configuracionEmpleadosStore.getFiltersEmpleados)) {
    filters[key] = ''
  }
  configuracionEmpleadosStore.setFiltersEmpleados(filters)
}

// Exportar a excel
const exportExcel = (filters) => {
  console.log('filters', filters)
}

// Hook onMounted
onMounted(async () => {
  await fetchCargos()
  await fetchAreas()

  // Setear los filtros del store al filters reactive
  for (const [key, value] of Object.entries(configuracionEmpleadosStore.getFiltersEmpleados)) {
    filters[key] = value
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-2">
    <div class="col-span-12 sm:col-span-2">
      <label for="cedula" class="block text-xs font-medium text-gray-700 mb-2">Cedula:</label>
      <InputText
        id="cedula"
        type="number"
        class="w-full text-sm"
        placeholder="Buscar por cedula..."
        v-model="filters.cedula"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.cedula ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="motivo" class="block text-xs font-medium text-gray-700 mb-2">Area:</label>
      <Dropdown
        id="motivo"
        v-model="filters.area"
        :options="listAreas"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.area ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="motivo" class="block text-xs font-medium text-gray-700 mb-2">Cargo:</label>
      <Dropdown
        id="motivo"
        v-model="filters.cargo"
        :options="listCargos"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.cargo ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <div class="col-span-12 lg:col-span-1 flex flex-wrap content-end">
      <div class="flex justify-between w-full">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="updateFilter"
          v-tippy="{ content: 'Buscar' }"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="resetClear"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <Button
          icon="pi pi-file-excel text-sm"
          severity="success"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="exportExcel(filters)"
          v-tippy="{ content: 'Exportar a Excel' }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
