import { defineStore } from 'pinia'
import EmpleadoService from '../services/empleado.service'
import ConfigAprobacionesService from '../services/config-aprobaciones.service'
// import dayjs from 'dayjs'

const _EmpleadoService = new EmpleadoService()
const _ConfigAprobacionesService = new ConfigAprobacionesService()

export const useConfiguracionEmpleadosStore = defineStore({
  id: 'configuracionEmpleados',
  state: () => ({
    _listadoEmpleados: [],
    _listAprobadoresComunes: [],
    _filtersEmpleados: {
      cedula: '',
      cargo: '',
      area: ''
    },
    _selectedEmpleados: [],
    _openModalConfig: false,
    _refrescarListadoEmpleados: false,
    _listAprobadoresByEmpleado: []
  }),
  getters: {
    getListadoEmpleados: (state) => state._listadoEmpleados,
    getListAprobadoresComunes: (state) => state._listAprobadoresComunes,
    getSelectedEmpleados: (state) => state._selectedEmpleados,
    getFiltersEmpleados: (state) => state._filtersEmpleados,
    getOpenModalConfig: (state) => state._openModalConfig,
    getRefrescarListadoEmpleados: (state) => state._refrescarListadoEmpleados,
    getListAprobadoresByEmpleado: (state) => state._listAprobadoresByEmpleado
  },
  actions: {
    async setListadoEmpleados (queryParams) {
      await _EmpleadoService.listarEmpleados(queryParams).then(({ data }) => {
        this._listadoEmpleados = data
      }).catch((error) => {
        console.log('Error en setListadoEmpleados', error)
      })
    },
    async setListAprobadoresComunes () {
      await _EmpleadoService.getAprobadoresComunes().then(({ data }) => {
        this._listAprobadoresComunes = data
      }).catch((error) => {
        console.log('Error en setListAprobadoresComunes', error)
      })
    },
    async setListAprobadoresByEmpleado (id) {
      await _ConfigAprobacionesService.findConfigAprobacionByEmpleado(id).then(({ data }) => {
        this._listAprobadoresByEmpleado = data
      }).catch((error) => {
        console.log('Error en setListAprobadoresEmpleado', error)
      })
    },
    setFiltersEmpleados (payload) {
      this._filtersEmpleados = payload
    },
    setSelectedEmpleados (payload) {
      this._selectedEmpleados = payload
    },
    setOpenModalConfig (payload) {
      this._openModalConfig = payload
    },
    setRefrescarListadoEmpleados (payload) {
      this._refrescarListadoEmpleados = payload
    }
  }
})
